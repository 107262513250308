<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:void(0);" @click="goBackNotice" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
<!--
      <div v-if="fileItems.length > 0" class="header-column header-util right">
        <div class="util util-download">
          <a href="javascript:" @click="isFileAtchListShow = true" class="util-actions util-actions-download">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </div>
      -->
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container helpdesk">
        <div class="view-header">
          <div class="title-wrap">
            <h3 class="title">{{items.ntcTitle}}</h3>
            <p class="subtitle"><span class="text">공지</span><span class="text">{{ getDateFormat('yyyy.MM.dd hh:mm', items.regDt) }} 등록</span><span
                class="text">{{ items.inqCnt }}
                  조회</span></p>
          </div>
        </div>
        <div class="view-body">
          <div class="view-content" v-html="items.ntcCn"></div>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>

import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {getItem, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {goBack} from '@/assets/js/modules/common/common';
import {
  ACT_GET_TRNCT_NOTICE,
  ACT_UPDATE_TRNCT_NOTICE_CNT
} from "@/store/modules/trnct/trnct";

export default {
  name: 'TrnctMobileNoticeView',
  components: {},
  setup(){
    const noticeTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);

    const trnctNtcSn = computed(() => route.params.trnctNtcSn);
    const isFileAtchListShow = ref(false);

    const getHelpNotice = () => {
      store.dispatch(`trnct/${ACT_GET_TRNCT_NOTICE}`,
          trnctNtcSn.value
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          //getHelpNoticeFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getHelpNotice();

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const pageViewCnt = async () => {
      if (parseInt(trnctNtcSn.value) > 0) {
        await store.dispatch(`trnct/${ACT_UPDATE_TRNCT_NOTICE_CNT}`, {trnctNtcSn: trnctNtcSn.value});
      }
    };

    onMounted(() => {
      pageViewCnt();
    });

    const goBackNotice = () =>{
      goBack();
    }

    return {
      noticeTitle,
      items,
      fileItems,
      showAttachments,
      getDateFormat,
      goBackNotice,
      isFileAtchListShow
    }

  }
}
</script>
